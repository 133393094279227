import React, {useEffect} from "react"
import Wrapper from "../components/calculator/Wrapper";
import uuid from 'react-uuid';
function Index(props) {
    useEffect(()=>{
        let session = localStorage.getItem('sussd-session')
        if (!session) {
            localStorage.setItem('sussd-session', uuid())
        }
    },[])
    return <Wrapper/>
}
export default Index

export function Head() {
    return <>
        <title>{`Rose Hill, Mallow`}</title>
        <meta charSet="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta name="author" content="Sussd Mortgages"/>
        <meta content="Register your interest in a new home in Rose Hill, Mallow" name="description"/>
        <meta content="Rose Hill, Mallow" property="og:title"/>
        <meta content="Register your interest in a new home in Rose Hill, Mallow" property="og:description"/>
        <meta content="Rose Hill, Mallow" property="twitter:title"/>
        <meta content="Register your interest in a new home in Rose Hill, Mallow"
              property="twitter:description"/>
        <meta property="og:type" content="website"/>
        <meta content="summary_large_image" name="twitter:card"/>
    </>
}