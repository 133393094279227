import React, {useState} from 'react';
import {Row,Col,ListGroup, ListGroupItem} from "react-bootstrap";
//import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
function Siteplan(props) {
    const [state,setState] = useState({zoomed:false})
    return (
        <Row>
            <Col lg={4}>
                <ListGroup className="px-3 ">
                    {Object.keys(props.homes).map(h=>{
                        return (
                            <ListGroupItem key={'list-item-' + props.homes[h].title} className="d-flex align-items-center">
                                <span className="me-3 rounded-pill flex-shrink-0" style={{height:'40px',width:'40px',backgroundColor:props.homes[h].color}}/>

                                <div className="d-flex flex-column w-100">
                                    <h6 className="me-3">{props.homes[h].title}</h6>
                                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between flex-lg-column ">
                                    <small>{props.homes[h].desc}</small>
                                    <small>{props.homes[h].size}</small>
                                    </div>
                                </div>
                            </ListGroupItem>

                        )

                    })}
                </ListGroup>
            </Col>
            <Col lg={8}>
                <div className="d-flex justify-content-center mt-3" >
                    <div className="d-lg-none">
                        <Zoom>
                            <img src={"/assets/images/plans/siteplan.svg"} style={{width:'100%',height:'auto'}} alt="Site Plan" />
                        </Zoom>
                        {/*<PinchZoomPan>*/}
                        {/*<img src={"/assets/images/plans/site-plan-p-1080.png"} alt="Site Plan" />*/}
                        {/*</PinchZoomPan>*/}
                    </div>
                    <div className="d-none d-lg-block siteplan-adjust">
                        <img src={"/assets/images/plans/siteplan.svg"} alt="Site Plan" />
                    </div>
                </div>
            </Col>



        </Row>
    );
}

export default Siteplan;