import React from 'react';
import {Col, Container, ListGroup, ListGroupItem, Row} from "react-bootstrap";
import {
    FaBath,
    FaBed,
    FaCertificate, FaCoffee, FaDoorClosed,
    FaDoorOpen,
    FaHome,
    FaPaintRoller,
    FaPowerOff, FaRecycle, FaShieldAlt, FaSun, FaThermometer, FaThermometerEmpty, FaThermometerHalf, FaTree,
} from "react-icons/fa";

function Benefits(props) {
    const size = 30
    const items =
        [
            [
                {
                    title: "BUILDING FABRIC",
                    icon: <FaSun size={size}/>,
                    specs: [
                        "Passive principles have been applied to the building fabric with a high-level of insulation, improved air-tightness, and reduced thermal bridges which result in a reduced energy demand and increased levels of comfort",
                    ]

                },
                {
                    title: "10 YEAR HOMEBOND GUARANTEE",
                    icon: <FaCertificate size={size}/>,
                    specs: [
                        "Each home is covered by a 10-year Structural Defects HomeBond insurance warranty and a 5-year Mechanical and Electrical Inherent Defects warranty offering unrivalled insurance protection for your home",
                    ]

                },
                {
                    title: "KITCHEN & UTILITY",
                    icon: <FaCoffee size={size}/>,
                    specs: [
                        "Imaginatively designed, superior quality kitchens which are fully fitted by our award winning suppliers. The utility rooms are also designed to maximise on storage with units that complement the kitchen. (PC Sum Allowance)"
                    ]

                },
                {
                    title: "GARDENS & DRIVEWAY",
                    icon: <FaTree size={size}/>,
                    specs: [
                        "Professionally designed landscaping throughout the development. All gardens are generous in size and come with seeded lawns surrounded by a secure 1.8m timber fence with concrete posts providing privacy on each side of the garden. Cobblelock paving and concrete footpaths provided as standard"
                    ]

                },
                {
                    title: "SECURITY & SAFETY",
                    icon: <FaShieldAlt size={size}/>,
                    specs:
                        [
                            "Mains-powered smoke, carbon monoxide and heat detectors are fitted throughout the house. All windows and doors come with multi-point locking systems"
                        ]

                }
            ],
            [
                {
                    title: "EXTERNAL WALLS",
                    icon: <FaHome size={size}/>,
                    specs: [
                        "Selected stock brick on certain elevations, painted smooth plaster rendered walls provide a high-quality, contemporary finish. Low maintenance uPVC fascia, soffit and rainwater pipes compliment the natural slate finish"
                    ]

                },
                {
                    title: "HEAT RECOVERY VENTILATION",
                    icon: <FaRecycle size={size}/>,
                    specs: [
                        "This system provides a healthier living environment while significantly reducing the heating demand. The outgoing air passes through a heat exchanger and preheats the incoming air, recovering 90% of the heat that would otherwise be lost. The air in the house is changed more frequently than naturally ventilated houses and all incoming air is filtered, reducing dust and other pollutants creating a fresher, healthier place to live"
                    ]

                },
                {
                    title: "INTERNAL FINISHES",
                    icon: <FaPaintRoller size={size}/>,
                    specs: [
                        "Increased ceiling heights create rooms that feel open and inviting. Superior-quality internal joinery is present throughout and includes oak handrails to stairs. A sealed attic hatch with pull-down ladder allows easy access to additional storage area",
                    ]

                },
                {
                    title: "HEATING",
                    icon: <FaThermometerHalf size={size}/>,
                    specs: [
                        "An ‘A’ Rated split bi-zone Air Source Heat Pump allows precise control over the three heating zones – living, sleeping and water. In addition to the separate heating zones, radiators are fitted with thermostatically-controlled valves providing additional room-by-room control"
                    ]

                }


            ],
            [
                {
                    title: "EXTERNAL DOOR",
                    icon: <FaDoorOpen size={size}/>,
                    specs: [
                        "Market leading heavy-duty engineered multi-point locking door giving the aesthetic appeal of a traditional timber door while providing exceptional strength, security and thermal performance characteristics",
                    ]
                },
                {
                    title: "INTERNAL DOORS",
                    icon: <FaDoorClosed size={size}/>,
                    specs: [
                        "High-quality oak finish doors are fitted with satin chrome-finished handles, hinges and locks",
                    ]
                },
                {
                    title: "SMART HEATING CONTROL",
                    icon: <FaThermometerEmpty size={size}/>,
                    specs: [
                        "The optional Online System allows full remote control of the heating and hot water system from any location. Temperatures and timings of individual zones can be adjusted via phone so you can arrive back to a warm home at any time of day or night. This level of functionality can help further reduce bills by switching off the heating if you are delayed",
                    ]
                },
                {
                    title: "BATHROOMS & ENSUITES",
                    icon: <FaBath size={size}/>,
                    specs: [
                        "All bathrooms and en-suites come fully-fitted with a sophisticated range of designer sanitary ware. A high-pressure water supply is pumped to all showers. (Shower door/screen not included).",
                    ]
                },
                {
                    title: "MEDIA & COMMUNICATIONS",
                    icon: <FaPowerOff size={size}/>,
                    specs: [
                        "High-speed data points and a generous allowance of high quality switches, sockets and telephone points throughout. The development provides for super-fast broadband and cable TV"
                    ]

                }
            ]
        ]

    return (
        <div className="pb-5">
            <Container className="px-xs-0-only">
                <h3 className="mt-5 mb-3 p-3 p-md-5 bg-info text-white">Rose Hill's Special Features</h3>
            </Container>
            <Container>
                <Row>
                    {items.map((col, i) => {

                        return (<Col key={`col-${i}`} xs={12} lg={4}>
                            {items[i].map(item => {
                                return (
                                    <div key={item.title}>
                                        <div
                                            className="d-flex align-items-end justify-content-between border-bottom border-info pb-1 my-3">
                                            <h5 className="mt-3 mb-0">{item.title}</h5>
                                            <span className="flex-shrink-0 text-info">
                                                {item.icon}
                                            </span>
                                        </div>
                                        <ListGroup>
                                            {item.specs.map(spec => {
                                                return <ListGroupItem className="bg-light" key={spec}>
                                                    {spec}
                                                </ListGroupItem>

                                            })}
                                        </ListGroup>
                                    </div>

                                )
                            })
                            }
                        </Col>)

                    })}
                </Row>
            </Container>
        </div>
    );
}

export default Benefits;