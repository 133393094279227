import React from 'react';

function Blurb(props) {

    return (
        <div>
            <h3><strong>Rose Hill</strong> in scenic Mallow</h3>
            <p>Nestled in the heart of scenic countryside, Mallow is a vibrant, bustling and unexpectedly charming town. Founded as a Spa town and holiday health retreat in 1724, after curative springs were discovered, Mallow has long been an oasis for rest, work and play.
            </p><p>    Known affectionately today as the Crossroads of Munster, this modern, thriving town has every amenity you might need. And yet, in a matter of minutes in either direction, you’ve the freedom to escape into an entirely different world of breathtaking lakes, rivers and mountain trails.
        </p><p>It’s a town that offers an unrivalled opportunity to live in the heart of it all. With every amenity and every escape, all there on your doorstep.</p>

        </div>
    );
}

export default Blurb;
