import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import CalculatorAccordion from "./CalculatorAccordion";
import "../../assets/sass/external.scss"
import Header from "./Header";
import Blurb from "./Blurb";
import CookieConsentBanner from "../CookieConsentBanner";
import FirstTimeBuyers from "./FirstTimeBuyers";

import Plans from "../Plans";
import Siteplan from "../Siteplan";
import Benefits from "../Benefits";
import RegisterInterest from "../RegisterInterest";

function Index(props) {
    const homes = {
        A: {
            title: "HOUSE TYPE A",
            desc: "4 Bedroom Detached",
            size: "227.70 sq.m | 2450.96 sq.ft",
            color: "#1c4f57",
            images: [
                "/assets/images/artwork/a.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Sitting Room', meters: 23.10, feet: 248.65},
                        {name: 'Kitchen/Dining Room', meters: 51.00, feet: 548.96},
                        {name: 'Living Room', meters: 16.90, feet: 181.91},
                        {name: 'WC', meters: 2.80, feet: 30.14},
                        {name: 'Utility Room', meters: 4.00, feet: 43.06},
                        {name: 'Study/TV Room', meters: 10.30, feet: 110.87}
                    ],
                    plan: '/assets/images/plans/type-a-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 17.90, feet: 192.68},
                        {name: 'Master Bedroom En-suite', meters: 3.70, feet: 39.83},
                        {name: 'Master Bedroom Wardrobe', meters: 3.30, feet: 35.52},
                        {name: 'Bedroom 1', meters: 14.20, feet: 152.85},
                        {name: 'Bedroom 1 En-suite', meters: 2.60, feet: 27.99},
                        {name: 'Bedroom 2', meters: 11.40, feet: 122.71},
                        {name: 'Bedroom 3', meters: 16.00, feet: 172.22},
                        {name: 'Bedroom 3 En-suite', meters: 2.60, feet: 27.99},
                        {name: 'Bathroom', meters: 11.10, feet: 119.48},
                        {name: 'Storage', meters: 1.80, feet: 19.38}
                    ],
                    plan: '/assets/images/plans/type-a-first.jpg'
                }
            },

        },
        B: {
            title: "HOUSE TYPE B",
            desc: "4 Bedroom Detached",
            size: "207.40 sq.m | 2232.45 sq.ft",
            color: "#d98722",
            images: [
                "/assets/images/artwork/b.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Sitting Room', meters: 22.10, feet: 237.88},
                        {name: 'Kitchen/Living Room', meters: 44.00, feet: 473.62},
                        {name: 'Dining Room', meters: 17.40, feet: 187.29},
                        {name: 'WC', meters: 2.40, feet: 25.83},
                        {name: 'Utility Room', meters: 3.60, feet: 38.75},
                        {name: 'Study/TV Room', meters: 11.30, feet: 121.63},
                    ],
                    plan: '/assets/images/plans/type-b-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 16.60, feet: 178.68},
                        {name: 'Master Bedroom En-suite', meters: 3.70, feet: 39.83},
                        {name: 'Master Bedroom Wardrobe', meters: 2.80, feet: 30.14},
                        {name: 'Bedroom 1', meters: 12.10, feet: 130.24},
                        {name: 'Bedroom 1 En-suite', meters: 2.70, feet: 29.06},
                        {name: 'Bedroom 2', meters: 11.40, feet: 122.71},
                        {name: 'Bedroom 3', meters: 13.90, feet: 149.62},
                        {name: 'Bathroom', meters: 8.60, feet: 92.57},
                        {name: 'Storage', meters: 2.90, feet: 31.22},
                    ],
                    plan: '/assets/images/plans/type-b-first.jpg'
                }
            },

        },
        C: {
            title: "HOUSE TYPE C",
            desc: "4 Bedroom Detached",
            size: "172.60 sq.m | 1857.87 sq.ft",
            color: "#af1858",
            images: [
                "/assets/images/artwork/c.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Kitchen/Breakfast Room', meters: 34.00, feet: 365.98},
                        {name: 'Dining Room', meters: 15.60, feet: 167.92},
                        {name: 'Utility', meters: 4.50, feet: 48.44},
                        {name: 'WC', meters: 2.60, feet: 27.99},
                        {name: 'Sitting Room', meters: 19.80, feet: 213.13},
                    ],
                    plan: '/assets/images/plans/type-c-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 17.30, feet: 186.22},
                        {name: 'Master Bedroom En-suite', meters: 4.10, feet: 44.13},
                        {name: 'Bedroom 1', meters: 14.30, feet: 153.93},
                        {name: 'Bedroom 2', meters: 11.70, feet: 125.94},
                        {name: 'Bedroom 3', meters: 11.40, feet: 122.71},
                        {name: 'Bathroom', meters: 4.60, feet: 49.51},
                        {name: 'Storage', meters: 1.00, feet: 10.76},
                    ],
                    plan: '/assets/images/plans/type-c-first.jpg'
                }
            },

        },
        D1D2: {
            title: "HOUSE TYPE D1/D2",
            desc: "4 Bedroom Semi-Detached",
            size: "139.50 sq.m | 1501.58 sq.ft",
            color: "#95d9ea",
            images: [
                "/assets/images/artwork/d1d2.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Sitting Room', meters: 19.00, feet: 204.52},
                        {name: 'Kitchen/Breakfast Room', meters: 25.60, feet: 275.56},
                        {name: 'Dining Room', meters: 15.60, feet: 167.92},
                        {name: 'Utility', meters: 2.60, feet: 27.99},
                        {name: 'WC', meters: 2.20, feet: 23.68},
                    ],
                    plan: '/assets/images/plans/type-d1d2-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 13.70, feet: 147.47},
                        {name: 'Master Bedroom En-suite', meters: 3.60, feet: 38.75},
                        {name: 'Bedroom 1', meters: 9.50, feet: 102.26},
                        {name: 'Bedroom 2', meters: 7.50, feet: 80.73},
                        {name: 'Bedroom 3', meters: 10.00, feet: 107.64},
                        {name: 'Bathroom', meters: 4.00, feet: 43.06},
                        {name: 'Storage', meters: 0.75, feet: 8.07},
                    ],
                    plan: '/assets/images/plans/type-d1d2-first.jpg'
                }
            },

        },
        E: {
            title: "HOUSE TYPE E",
            desc: "3 Bedroom Semi-Detached",
            size: "124.20 sq.m | 1336.89 sq.ft",
            color: "#9f99bc",
            images: [
                "/assets/images/artwork/e.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Sitting Room', meters: 18.70, feet: 201.29},
                        {name: 'Kitchen/Breakfast Room', meters: 19.60, feet: 210.97},
                        {name: 'Dining Room', meters: 15.60, feet: 167.92},
                        {name: 'Utility', meters: 2.40, feet: 25.83},
                        {name: 'WC', meters: 2.20, feet: 23.68},
                    ],
                    plan: '/assets/images/plans/type-e-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom 1', meters: 14.60, feet: 157.15},
                        {name: 'Master Bedroom 1 En-suite', meters: 2.60, feet: 27.99},
                        {name: 'Bedroom 2', meters: 13.30, feet: 143.16},
                        {name: 'Bedroom 3', meters: 8.30, feet: 89.34},
                        {name: 'Bathroom', meters: 4.20, feet: 45.21},
                        {name: 'Storage', meters: 0.73, feet: 7.86},
                    ],
                    plan: '/assets/images/plans/type-e-first.jpg'
                }
            },

        },
        G1G2: {
            title: "HOUSE TYPE G1/G2",
            desc: "4 Bedroom Semi-Detached",
            size: "139.20 sq.m | 1498.35 sq.ft",
            color: "#008aad",
            images: [
                "/assets/images/artwork/g1g2.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Living Room', meters: 16.50, feet: 177.61},
                        {name: 'Kitchen/Dining Room', meters: 40.20, feet: 432.71},
                        {name: 'Utility', meters: 3.50, feet: 37.67},
                        {name: 'WC', meters: 2.30, feet: 24.76},
                        {name: 'Storage', meters: 2.50, feet: 26.91},
                    ],
                    plan: '/assets/images/plans/type-g1g2-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 14.00, feet: 150.70},
                        {name: 'Master Bedroom En-suite', meters: 2.40, feet: 25.83},
                        {name: 'Bedroom 2', meters: 11.60, feet: 124.86},
                        {name: 'Bedroom 3', meters: 11.50, feet: 123.79},
                        {name: 'Bedroom 4', meters: 8.30, feet: 89.34},
                        {name: 'Bathroom', meters: 4.30, feet: 46.29},
                    ],
                    plan: '/assets/images/plans/type-g1g2-first.jpg'
                }
            },

        },
        G3G4: {
            title: "HOUSE TYPE G3/G4",
            desc: "4 Bedroom Semi-Detached",
            size: "144.20 sq.m | 1552.17 sq.ft",
            color: "#f3c300",
            images: [
                "/assets/images/artwork/g3g4.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Living Room', meters: 24.50, feet: 263.72},
                        {name: 'Kitchen/Dining Room', meters: 36.30, feet: 390.73},
                        {name: 'Utility', meters: 3.50, feet: 37.67},
                        {name: 'WC', meters: 2.30, feet: 24.76},
                    ],
                    plan: '/assets/images/plans/type-g1g2-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 13.40, feet: 144.24},
                        {name: 'Master Bedroom En-suite', meters: 2.40, feet: 25.83},
                        {name: 'Bedroom 2', meters: 11.80, feet: 127.02},
                        {name: 'Bedroom 3', meters: 11.40, feet: 122.71},
                        {name: 'Bedroom 4', meters: 8.20, feet: 88.26},
                        {name: 'Bathroom', meters: 3.60, feet: 38.75},
                        {name: 'Storage', meters: 2.00, feet: 21.53},
                    ],
                    plan: '/assets/images/plans/type-g1g2-first.jpg'
                }
            },

        },
        H1: {
            title: "HOUSE TYPE H1",
            desc: "3 Bedroom Terrace",
            size: "144.20 sq.m | 1552.17 sq.ft",
            color: "#c77595",
            images: [
                "/assets/images/artwork/h1j1.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Living Room', meters: 16.00, feet: 172.22},
                        {name: 'Kitchen/Dining Room', meters: 18.50, feet: 199.13},
                        {name: 'Utility', meters: 3.40, feet: 36.60},
                        {name: 'WC', meters: 2.30, feet: 24.76},
                        {name: 'Storage', meters: 2.00 , feet:21.53},
                    ],
                    plan: '/assets/images/plans/type-h1-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom', meters: 15.90, feet: 171.15},
                        {name: 'Bedroom 2', meters: 13.00, feet: 139.93},
                        {name: 'Bedroom 3', meters: 7.10, feet: 76.42},
                        {name: 'Bathroom', meters: 4.90 , feet:52.74},
                        {name: 'Storage', meters: 1.00, feet: 10.76},
                    ],
                    plan: '/assets/images/plans/type-h1-first.jpg'
                }
            },

        },
        J1: {
            title: "HOUSE TYPE J1",
            desc: "2 Bedroom Terrace",
            size: "88.70 sq.m | 954.77 sq.ft",
            color: "#c77595",
            images: [
                "/assets/images/artwork/h1j1.jpg"
            ],
            floors: {
                ground: {
                    rooms: [
                        {name: 'Kitchen/Living/Dining Room',meters: 33.10,  feet:356.29},
                        {name: 'WC',meters: 1.50,  feet:16.15},
                    ],
                    plan: '/assets/images/plans/type-j1-ground.jpg'
                },
                first: {
                    rooms: [
                        {name: 'Master Bedroom',meters: 13.00,  feet: 139.93},
                        {name: 'Bedroom 2',meters: 12.00,  feet: 129.17},
                        {name: 'Bathroom',meters: 4.70,  feet: 50.59},
                        {name: 'Storage',meters: 5.40,  feet: 58.13},
                    ],
                    plan: '/assets/images/plans/type-j1-first.jpg'
                }
            },

        },
    }

    return (
        <>
            <div className="min-vh-100 w-100 iframe-container">
                <Header/>

                <Container className="px-xs-0-only position-relative">
                    <Row className="py-4 gx-0 gx-sm-5">
                        <Col className="blurb order-2 order-lg-1" xs={12} lg={7}>
                            <Card className="mt-3 bg-body-color">
                                <Card.Body className="p-md-5">
                                    <Blurb/>
                                </Card.Body>
                                <div className="bg-img1 text-white"
                                     style={{backgroundImage: 'url("/assets/images/mallow.png")'}}>
                                    {/*<div*/}
                                    {/*    className="d-flex flex-column flex-sm-row justify-content-around align-content-stretch  position-relative text-start text-sm-center">*/}
                                    {/*    <div className="p-2 py-3 small" style={{backgroundColor: 'rgba(0,59,74,0.75)'}}>*/}
                                    {/*        Natural stone on certain elevations and boundaries*/}
                                    {/*    </div>*/}
                                    {/*    <div className="p-2 py-3 small "*/}
                                    {/*         style={{backgroundColor: 'rgba(29,99,116,0.75)'}}>*/}
                                    {/*        Architecturally designed unique homes*/}
                                    {/*    </div>*/}
                                    {/*    <div className="p-2 py-3 small "*/}
                                    {/*         style={{backgroundColor: 'rgba(87,144,158,0.75)'}}>*/}
                                    {/*        Elevated ceilings make for rooms that feel open*/}
                                    {/*    </div>*/}
                                    {/*    <div className="p-2 py-3 small"*/}
                                    {/*         style={{backgroundColor: 'rgba(127,168,174,0.75)'}}>*/}
                                    {/*        Professionally designed landscaping throughout*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                </div>
                                <Card.Body className="p-md-5">
                                    <h3>For First Time Buyers</h3>
                                    <FirstTimeBuyers/>
                                </Card.Body>

                            </Card>
                            <Row className="my-3 my-md-5 mx-5 px-0 px-md-5 align-items-center">
                                <Col>
                                    <img className=" w-100" src="/assets/images/ber-a.png" alt="BER A"
                                         loading="lazy"/>

                                </Col>
                                <Col>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.09 18.71">
                                        <title>ber-a2</title>
                                        <g id="Layer_2" data-name="Layer 2">
                                            <g id="type">
                                                <g>
                                                    <polygon points="0 0 0 18.71 49.26 18.71 53.09 9.06 49.19 0 0 0"
                                                             fill="#fff"></polygon>
                                                    <polygon
                                                        points="47.77 2.14 29.79 2.14 32.88 9.12 29.79 16.58 47.77 16.58 50.76 9.06 47.77 2.14"
                                                        fill="#00a650"></polygon>
                                                    <path
                                                        d="M36.66,10.23h2l-1-3.16h0Zm-1,3H33.9l2.85-8h1.93l2.81,8H39.68l-.51-1.65h-3Z"
                                                        fill="#fff"></path>
                                                    <path
                                                        d="M42.1,8.15c0-2.57,1.87-2.79,2.58-2.79a2.46,2.46,0,0,1,2.71,2.49,3.05,3.05,0,0,1-1.64,2.57c-1,.73-1.6,1.08-1.78,1.45H47.4v1.38H42a3.52,3.52,0,0,1,1.83-3.13c1.43-1,2-1.44,2-2.33a1.07,1.07,0,0,0-1.1-1.12c-1.09,0-1.12.9-1.13,1.48Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                    <path d="M27.47,2.14l3.08,7-3.08,7.46m0,0H2.14V2.14H27.47"></path>
                                                    <path
                                                        d="M6.92,11.94H9c.81,0,1.27-.35,1.27-1.16s-.6-1-1.2-1H6.92Zm0-3.47H8.87c.66,0,1.18-.26,1.18-.89s-.46-.86-1.22-.86H6.92ZM5.32,5.35H9.2c2,0,2.46,1.22,2.46,2a1.59,1.59,0,0,1-1,1.63A1.86,1.86,0,0,1,11.91,11a2.28,2.28,0,0,1-.69,1.64c-.45.44-.89.72-2.52.72H5.32Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                    <polygon
                                                        points="18.81 6.76 14.57 6.76 14.57 8.44 18.47 8.44 18.47 9.85 14.57 9.85 14.57 11.91 19 11.91 19 13.32 12.94 13.32 12.94 5.35 18.81 5.35 18.81 6.76"
                                                        fill="#fff"></polygon>
                                                    <path
                                                        d="M23.54,8.86c.87,0,1.29-.3,1.29-1.09,0-.43-.18-1-1.16-1H21.59V8.86ZM20,5.35h4A2.24,2.24,0,0,1,26.5,7.6a1.9,1.9,0,0,1-1.23,1.9c.66.29,1,.53,1,2,0,1.17,0,1.46.36,1.59v.21H24.79a5.35,5.35,0,0,1-.18-1.61c0-1.09-.07-1.51-1.3-1.51H21.59v3.12H20Z"
                                                        fill="#fff"></path>
                                                    <rect width="53.09" height="18.71" fill="none"></rect>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </Col>
                            </Row>


                        </Col>
                        <Col className="order-1 order-lg-2 " xs={12} lg={5}>
                            <div className=" shadow mt-lg--100 mb-5">
                            <RegisterInterest/>

                            </div>


                            <CalculatorAccordion/>

                        </Col>
                    </Row>


                </Container>

                <Benefits/>

                <div className="bg-body-color py-lg-5">
                    <Container className="px-xs-0-only" style={{overflowX: 'hidden'}}>
                        <h3 className="text-center my-5">Floor Plans</h3>
                        <Plans homes={homes}/>
                    </Container>
                </div>
                <div className="bg-white py-5">
                    <Container className="px-xs-0-only">
                        <h3 className="text-center my-5">Site Plan</h3>

                        <Siteplan homes={homes}/>
                    </Container>
                </div>
                <div className="bg-body-color py-lg-5">
                    <Container className="px-xs-0-only text-center">
                        <h3 className="text-center my-5">Location</h3>

                        <img src="/assets/images/plans/map.svg" alt="Map to Rose Hill" loading="lazy" className="w-100"/>
                    </Container>
                </div>


                <div className="bg-info text-info py-3 py-lg-4">
                    <Container className="px-xs-0-only">
                        <Row className="gx-0 gx-sm-3">
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center h-100 py-4 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                        <h3> SELLING AGENTS</h3>
                                        <div className="mt-auto">
                                            <img src="/assets/images/logo-savills.jpg" loading="lazy" alt="Savills Logo"/>
                                        </div>

                                    </div>
                                </div>

                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center py-4 h-100 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                        <h3> DEVELOPER</h3>
                                        <div className="mt-auto d-flex justify-content-center">
                                            <div className="bg-info p-3">
                                            <img src="/assets/images/logo-reside.svg" loading="lazy" alt="Reside Logo"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center py-4 h-100 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                        <h3 > PROJECT MANAGER</h3>
                                        <div className="mt-auto">
                                            <img src="/assets/images/logo-hallmark.jpg" loading="lazy" alt="Hallmark Logo"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} md={6} lg={3} className="py-3">
                                <div className="px-4 px-sm-0 h-100">
                                    <div className="text-center py-4 h-100 d-flex flex-column" style={{backgroundColor:'#d3d3d3'}}>
                                        <h3 > MORTGAGE PARTNER</h3>
                                        <div className="mt-auto mb-2">
                                            <img src="/assets/images/logo-sussd.svg" loading="lazy" alt="Sussd Logo"/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>
            <CookieConsentBanner/>
        </>
    );
}

export default Index;