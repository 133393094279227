import React, {useEffect, useRef, useState} from 'react';
import Rate from "./Rate";
import {Button,Card} from "react-bootstrap";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";

function RatesCard(props) {
    let len = props.rates.length
    const [state,setState] = useState({
        index: 0,
        marginLeft:0
    })
    const ref = useRef();
    useEffect(() => {
        if (props.term !== ref.current){
            ref.current = props.term;
            setState(s=>{
                return {...s,index: 0}
            })

        }
    },[props.term]);

    const goBack=()=>{
        setState({...state,index:state.index -1,position:'left'})
    }
    const goForward=()=>{
        setState({...state,index:state.index +1,position:'right'})
    }

    len = isNaN(len) ? 1 : len
    return (
        <div className={`rates-card h-100`}>
            <div className={`w-100 overflow-hidden pt-3`}>
                {
                    props.rates.length > 0 && (

                        <div className="d-flex flex-nowrap sliding h-100" style={{width: (len * 100) + '%',marginLeft: `-${state.index * 100}%`}}>
                            {
                                props.rates.map((item,i)=>{
                                    return (
                                        <div key={item.id}  className="px-3 w-100">
                                            <Rate delay={props.delay} variant={props.variant} rate={item}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )

                }

            </div>

            <Card.Footer className="d-flex p-3">
                <Button className={`py-0 border-0 ${state.index === 0 ? 'disabled text-secondary':''}`} variant="transparent" onClick={goBack}><FaChevronLeft/></Button>
                <Button className={`py-0 ms-auto border-0 ${state.index === len-1 ? 'disabled text-secondary':''}`} variant="transparent" onClick={goForward}><FaChevronRight/></Button>
            </Card.Footer>
        </div>
    );
}

export default RatesCard;