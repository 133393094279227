import React, {useCallback, useState} from 'react';
import ImageViewer from "react-simple-image-viewer";
import {Card, Col, Row} from "react-bootstrap";

function Plans(props) {
    const [state, setState] = useState({activePlan: false, activeIndex: 0})
    const openImageViewer = useCallback((home, index) => {
        setState(prev => {
            return {...prev, activePlan: home, activeIndex: index}
        })
    }, []);
    const closeImageViewer = () => {
        setState(prev => {
            return {...prev, activePlan: false, activeIndex: 0}
        })
    };

    return (
        <>
        <Row>
            {
                Object.keys(props.homes).map(home => {

                    return (

                        <Col xs={12} lg={6} className="my-5" key={props.homes[home].title + "-col"}>
                            <Card className="bg-white shadow h-100">
                                <Card.Img loading="lazy" variant="top" src={props.homes[home].images[0]}
                                          onClick={() => openImageViewer(home, 0)}/>
                                <Card.Header className="py-5">
                                    <Card.Title className="mb-3">{props.homes[home].title}</Card.Title>
                                    <Card.Subtitle className={"mb-2"}>{props.homes[home].desc}</Card.Subtitle>
                                    <Card.Subtitle>{props.homes[home].size}</Card.Subtitle>
                                </Card.Header>

                                <Row>
                                    {Object.keys(props.homes[home].floors).map((floorName, i) => {
                                        return (
                                            <Col key={props.homes[home].title + "-floor-" + i}>
                                                <Card.Body onClick={() => openImageViewer(home, i+1)}>
                                                    <img loading="lazy" src={props.homes[home].floors[floorName].plan} className="w-100 my-3"/>
                                                    <table className="table table-sm">
                                                        <thead>
                                                        <tr>
                                                            <th>{floorName.toUpperCase()} Floor</th>
                                                            <th className="text-end">Meters</th>
                                                            <th className="text-end">Feet</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            props.homes[home].floors[floorName].rooms.map(room => {
                                                                return <tr key={props.homes[home].title + "-floor-" + floorName + room.name}>
                                                                    <td>{room.name}</td>
                                                                    <td className="text-end">{room.meters}</td>
                                                                    <td className="text-end">{room.feet}</td>
                                                                </tr>

                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </Card.Body>
                                            </Col>

                                        )

                                    })}

                                </Row>


                            </Card>
                        </Col>

                    )
                })

            }
            </Row>

            {state.activePlan && (
                <ImageViewer
                    src={[...props.homes[state.activePlan].images, props.homes[state.activePlan].floors.ground.plan, props.homes[state.activePlan].floors.first.plan]}
                    currentIndex={state.activeIndex}
                    onClose={closeImageViewer}
                    disableScroll={true}
                    backgroundStyle={{
                        backgroundColor: "rgba(255,255,255,1)"
                    }}
                    closeOnClickOutside={true}
                />
            )}

        </>)

}

export default Plans;